import React from 'react';
import InfiniteScrollTable from './InfiniteScrollTable';

const App = () => {
  return (
      <InfiniteScrollTable />
  );
};

export default App;
